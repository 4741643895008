import React, { useEffect } from 'react';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import { Navbar, Hero, Welcome, AboutUs, WaterActivities, FoodAndDrinks, Divider, Testimonials, PicturesGallery, Groups, ContactForm, Footer, Top ,HeroCards} from './components';
import Impressum from './pages/Impressum/Impressum';
import Datenschutz from './pages/Datenschutz/Datenschutz';

const MainContent = () => {
  return (
    <>
    <Top />
    <HeroCards/>
      <Welcome data-aos="fade-up" />
      <AboutUs data-aos="fade-up" />
      <Groups data-aos="fade-up" />
      <FoodAndDrinks data-aos="fade-up" />

      <WaterActivities data-aos="fade-up" />
      <Testimonials data-aos="fade-up" />
      <PicturesGallery data-aos="fade-up" />
      <ContactForm data-aos="fade-up" />
      <Footer data-aos="fade-up" />
    </>
  );
};

const ImpressumPage = () => {
  return (
    <>
    <Navbar/>
    <Impressum/>
    <Footer data-aos="fade-up"/>
    </>
  );
};

const AppContent = () => {
  const location = useLocation();

  // Bedingtes Rendering für MainContent, Top und Footer
  const shouldShowMainContent = location.pathname === '/';

  return (
    <>
      
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/impressum" element={<ImpressumPage />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
      </Routes>
    </>
  );
};

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
